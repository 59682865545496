import { createContext, ReactNode, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import * as Sentry from "@sentry/nextjs"

import { ApiCode } from 'enums/apicode'
import { fromLocal, toLocal } from 'lib/storage'
import { isDarkPreferred, isLightPreferred } from 'lib/theme'
import User, { Profile, Theme } from 'types/user'
import { SignInRequestData, SignInResponseData } from 'types/auth'
import { APIRelationshipsEntities } from 'types/api'
import { setAuthToken } from 'services/api'
import { getAuthToken, getUserInfo, keepSignedIn, signInRequest, signOut } from 'services/auth'
import { updatePreferences } from 'services/user'

interface AuthContextType {
  user: User | null,
  isAuthenticated: boolean,
  theme: Theme
  /* eslint-disable no-unused-vars */
  signIn: (data: SignInRequestData) => Promise<Boolean>,
  setProfile: (data: Profile) => void,
  setLogged: (data: SignInResponseData) => Promise<Boolean>,
  setThemeMode: (theme: Theme) => void,
  logoutUser: () => void
}

interface ProfileFromRelationship extends Profile {
  type?: APIRelationshipsEntities
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider(props: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const isAuthenticated = !!user
  const DEFAULT_THEME: Theme = 'dark'
  const [theme, setTheme] = useState<Theme>(DEFAULT_THEME)
  const router = useRouter()

  function formatUserForSaving(user: any) {
    const userInfo = {
      id: user.id,
      email: user.email,
      status: user.status,
      profile: user.profile || user.relationships?.profile as ProfileFromRelationship,
      created_at: user.created_at
    }
    userInfo.profile && delete userInfo.profile.type
    return userInfo
  }

  const updateUserProfileFromAPI = useCallback(() => {
    if(!user){
      getUserInfo().then(response => {
        setUser(formatUserForSaving(response))
      }).catch((error) => {
        if (error?.response?.status === 401) {
          signOut()
          router.push('/auth/login?')
        }
      })
    }
  }, [router])

  async function signIn({ email, password }: SignInRequestData): Promise<Boolean> {
    try {
      const signedIn = await signInRequest({ email, password })
      const logged = await setLogged(signedIn)
      if (!logged) throw new Error("Logged error")
      return Promise.resolve(true)
    } catch (error: any) {
      if (error.code === ApiCode.USER_ALREADY_LOGGED) {
        return true
      }
      return false
    }
  }

  async function setLogged({ access_token, token_type, user }: SignInResponseData): Promise<Boolean> {
    try {
      if (!access_token) throw new Error("Access Token required")
      setAuthToken(token_type, access_token)
      keepSignedIn(access_token)
      if (user) {
        setUser(formatUserForSaving(user))
      } else {
        updateUserProfileFromAPI()
      }
      return Promise.resolve(true)
    } catch (error) {
      Sentry.captureException(error)
      return false
    }
  }

  function setProfile(data: Profile) {
    const updated = {
      ...data
    }
    setUser((prev) => {
      if (!prev) throw new Error("User not found")
      return { ...prev, profile: updated }
    })
  }

  function setThemeMode(mode: Theme = DEFAULT_THEME) {
    document && document.body.setAttribute('data-theme', mode)
    setTheme(mode)
    toLocal('theme', mode)
    updatePreferences({ theme: mode })
  }

  function logoutUser() {
    setUser(null)
  }

  useEffect(() => {
    getAuthToken() && updateUserProfileFromAPI()
  }, [updateUserProfileFromAPI])

  useEffect(() => {
    const preferences = user?.preferences ? JSON.parse(user.preferences) : null
    const localTheme = fromLocal('theme') as Theme

    if (preferences?.theme) {
      setThemeMode(preferences.theme)
      return
    }

    if (['dark', 'light'].includes(localTheme as string)) {
      setThemeMode(localTheme)
      return
    }

    if (isLightPreferred()) {
      setTheme('light')
      return
    }

    if (isDarkPreferred()) {
      setTheme('dark')
      return
    }

    setThemeMode(DEFAULT_THEME)
  }, [user?.preferences])

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn, setLogged, setProfile, theme, setThemeMode, logoutUser }}>
      <div data-theme={theme} data-testid='auth-provider-container'>
        {props.children}
      </div>
    </AuthContext.Provider>
  )
}
