var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"88d552cf6e50c4ff47a76ff9d7d677701bf71a6d"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if(process.env.NEXT_PUBLIC_VERCEL_ENV) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
    dsn: SENTRY_DSN || 'https://0b8ca1dbb37a4b2bbfd39676986b3bc0@o1376269.ingest.sentry.io/6685259',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.2,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
