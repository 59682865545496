import { api } from './api'
import { Address, Preferences, Profile, SocialLink } from 'types/user'
import { CreditCard } from 'types/payment'

export async function getProfile(profileId: string): Promise<Profile> {
  const response = await api.get(`/user/profile/${profileId}`)
  const profile = response.data

  return new Promise(resolve => resolve(profile))
}

export async function createProfile(data: Partial<Profile>): Promise<Profile> {
  const response = await api.post(`/user/profile`, data)
  const profile = response.data

  return new Promise(resolve => resolve(profile))
}

export async function updateProfile(data: Partial<Profile>): Promise<Profile> {
  const response = await api.put(`/user/profile`, data)
  const profile = response.data

  return new Promise(resolve => resolve(profile))
}

export async function updatePreferences(data: Partial<Preferences>): Promise<Preferences> {
  // const response = await api.put(`/user`, {preferences: data})
  // const preferences = response.data.preferences
  const preferences = data as Preferences

  return new Promise(resolve => resolve(preferences))
}

export async function addCreditCard(userId: string, data: CreditCard): Promise<CreditCard> {
  const response = await api.post(`/user/${userId}/creditcard`, data)
  const added = response.data

  return new Promise(resolve => resolve(added))
}

export async function getAddresses(): Promise<Array<Address>> {
  const response = await api.get(`/user/addresses`)
  const list = response.data.addresses

  return new Promise(resolve => resolve(list))
}

export async function createAddress(data: Partial<Address>): Promise<Address> {
  const response = await api.post(`/user/address`, data)
  const address = response.data.address

  return new Promise(resolve => resolve(address))
}

export async function updateAddress(data: Address): Promise<Address> {
  const response = await api.put(`/user/address/${data.id}`, data)
  const address = response.data.address

  return new Promise(resolve => resolve(address))
}

export async function getSocialLinks(): Promise<Array<SocialLink>> {
  const response = await api.get(`/social-links`)
  const list = response.data.map((r: SocialLink) => {
    return { ...r, status: r.status === 'active' ? true : false }
  })

  return new Promise(resolve => resolve(list))
}
export async function createSocialLink(data: Partial<SocialLink>): Promise<SocialLink> {
  data.status = data.status ? 'active' : 'inactive'
  const response = await api.post(`/social-link`, data)
  const link = response.data

  return new Promise(resolve => resolve(link))
}

export async function updateSocialLink(data: SocialLink): Promise<SocialLink> {
  data.status = data.status ? 'active' : 'inactive'
  const response = await api.put(`/social-link/${data.id}`, data)
  const link = response.data

  return new Promise(resolve => resolve(link))
}

export async function deleteSocialLink(data: SocialLink): Promise<SocialLink> {
  const response = await api.delete(`/social-link/${data.id}`)
  const link = response.data

  return new Promise(resolve => resolve(link))
}

export function validateDocumentBR(doc?: string) {
  if (!doc) return false
  doc = doc.replaceAll(/\D/g, '')

  if (doc.length > 11) {
    return validateCNPJ(doc)
  }

  return validateCPF(doc)
}


export function validateCPF(cpf?: string) {
  if (!cpf) return false
  cpf = cpf.replaceAll(/\D/g, '')
  if (cpf.length !== 11) return false
  if (/\b(\d)\1+\b/.test(cpf)) return false

  let sum = 0
  let mod

  for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  mod = (sum * 10) % 11

  if ((mod == 10) || (mod == 11)) mod = 0
  if (mod != parseInt(cpf.substring(9, 10))) return false

  sum = 0
  for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  mod = (sum * 10) % 11

  if ((mod == 10) || (mod == 11)) mod = 0
  if (mod != parseInt(cpf.substring(10, 11))) return false

  return true
}

export function validateCNPJ(cnpj?: string) {
  if (!cnpj) return false
  cnpj = cnpj.replace(/[^\d]+/g, '')
  if (cnpj.length !== 14) return false
  if (/0{14}/.test(cnpj)) return false

  const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

  for (var i = 0, d1 = 0; i < 12; d1 += Number(cnpj[i]) * b[++i]);
  if (Number(cnpj[12]) !== (((d1 %= 11) < 2) ? 0 : 11 - d1)) return false

  for (var y = 0, d2 = 0; y <= 12; d2 += Number(cnpj[y]) * b[y++]);
  if (Number(cnpj[13]) !== (((d2 %= 11) < 2) ? 0 : 11 - d2)) return false

  return true
}

export function maskCPForCNPJ(doc?: string) {
  if (!doc) return ''
  doc = doc.replaceAll(/\D/g, '')

  if (doc.length > 11) {
    return doc?.slice(0, 2) + '.' + doc?.slice(2, 5) + '.' + doc?.slice(5, 8) + '/' + doc?.slice(8, 12) + '-' + doc?.slice(12, 14)
  }

  return doc?.slice(0, 3) + '.' + doc?.slice(3, 6) + '.' + doc?.slice(6, 9) + '-' + doc?.slice(9, 11)
}

export const SOCIAL_LINKS_OPTIONS = [
  { label: 'Instagram', key: 'instagram' },
  { label: 'Facebook', key: 'facebook' },
  { label: 'TikTok', key: 'tiktok' },
  { label: 'LinkedIn', key: 'linkedin' },
  { label: 'YouTube', key: 'youtube' },
  { label: 'Spotify', key: 'spotify' },
  { label: 'Telegram', key: 'telegram' },
  { label: 'Twitter', key: 'twitter' },
  { label: 'Other', key: 'other' }
]
