export const toSession = (key: string, value: string) => {
  return sessionStorage.setItem(key, value)
}

export const fromSession = (key: string) => {
  return sessionStorage.getItem(key)
}

export const clearSession = (key: string) => {
  return sessionStorage.removeItem(key)
}

export const toLocal = (key: string, value: string) => {
  if (typeof window === 'undefined') return
  return localStorage.setItem(key, value)
}

export const fromLocal = (key: string) => {
  if (typeof window === 'undefined') return
  return localStorage.getItem(key)
}

export const removeLocal = (key: string) => {
  if (typeof window === 'undefined') return
  return localStorage.removeItem(key)
}
