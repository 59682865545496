/* eslint no-unused-vars: "off" */
import { ReactElement, ReactNode, useEffect } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { GTM_ID, pageview } from 'lib/gtm'
import { ToastProvider } from 'contexts/ToastContext'
import { AuthProvider } from 'contexts/AuthContext'
import { DeviceProvider } from 'contexts/DeviceContext'

import 'styles/variables.css'
import 'styles/globals.scss'
import 'styles/ps-stylesheet.scss'
import 'styles/custom-scrollbar.scss'
import 'styles/richtext/custom-richtext-editor.scss'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)
  const pageContent = getLayout(
    <Component {...pageProps} />
  )
  const router = useRouter()
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Head>
      {GTM_ID && <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
      </>}
      <DeviceProvider>
          <AuthProvider>
        <ToastProvider>
            {pageContent}
        </ToastProvider>
          </AuthProvider>
      </DeviceProvider>
    </>
  )
}
