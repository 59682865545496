import { createContext, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

interface DeviceContextType {
  isTabletOrMobile: boolean,
}

interface Props {
  children: ReactNode
}

export const DeviceContext = createContext({} as DeviceContextType)

export function DeviceProvider(props: Props) {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 991 })

  return (
    <DeviceContext.Provider value={{ isTabletOrMobile }}>
      {props.children}
    </DeviceContext.Provider>
  )
}
