import axios from 'axios'

import { APIPatternResponse, APIRelationshipsEntities } from 'types/api'
import { getAuthToken } from 'services/auth'
import { FileFormat, downloadFile, fileType } from 'lib/download-file'

const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL
const token = getAuthToken()

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json"
  }
})

interface responseDownload {
  file: string
}

export const download = async (baseUrl: string, format: FileFormat, params: any) => {

  const headers = {
    Accept: fileType(format)
  }

  const response: responseDownload = await api.get(baseUrl, { headers, params })

  return downloadFile(response, format)
}

export const mergeRelationshipData = (source: Partial<any & APIPatternResponse>, index?: APIRelationshipsEntities): Partial<any & APIPatternResponse> => {
  try {
    if (!source.relationships) throw new Error("There is no relationship available")
    const indexToMerge = (!index) ? Object.keys(source.relationships) : [index]

    indexToMerge.forEach(i => {
      const relatedData = source.relationships[i]?.data
      source[i] = relatedData
    })

    delete source.relationships
    delete source.links

    return source
  } catch (error) {
    return source
  }
}

export const setAuthToken = (token_type: string, access_token: string) => {
  api.defaults.headers.common['Authorization'] = `${token_type} ${access_token}`
}

export const removeAuthToken = () => {
  api.defaults.headers.common['Authorization'] = ''
  delete api.defaults.headers.common['Authorization']
}

api.interceptors.request.use(request => {
  return request
})

api.interceptors.response.use(
  response => response.data,
  error => {
    // if(error?.response?.status === 401) signOut(error)
    return Promise.reject(error)
  }
)

if (token) setAuthToken("Bearer", token)
