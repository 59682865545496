export type FileFormat = 'PDF' | 'XLS' | 'CSV'

export const fileType = (format: FileFormat): string => {
  let contentType: string

  switch (format) {
    case 'PDF':
      contentType = 'application/pdf'
      break
    case 'XLS':
      contentType = 'application/vnd.ms-excel'
      break
    case 'CSV':
      contentType = 'text/csv'
      break
    default:
      throw new Error('Formato inválido!')
  }

  return contentType
}

export const downloadFile = async (
  { file }: { file: string },
  format: FileFormat,
): Promise<void> => {

  const contentType = fileType(format)

  const response = await fetch(`data:${contentType};base64,${file}`)
  const buffer = await response.arrayBuffer()
  const bytes = new Uint8Array(buffer)
  const BYTE_SIZE = 1024
  const byteArrays = []

  for (let i = 0; i < bytes.length; i += BYTE_SIZE) {
    byteArrays.push(bytes.slice(i, i + BYTE_SIZE))
  }

  const blob = new Blob(byteArrays, { type: contentType })
  const filename = `${new Date().getTime()}.${format.toLowerCase()}`
  const downloadLink = document.createElement('a')
  downloadLink.href = URL.createObjectURL(blob)
  downloadLink.setAttribute('download', filename)
  document.body.appendChild(downloadLink)

  try {
    downloadLink.click()
  } finally {
    document.body.removeChild(downloadLink)
  }
}
