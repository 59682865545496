import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import * as ToastPrimitive from '@radix-ui/react-toast'

import type { ToastInterface } from 'types/toast'
import { AuthContext } from './AuthContext'
import Icon from 'components/common/icon'

import style from './Toast.module.scss'
interface ToastContextType {
  /* eslint-disable-next-line no-unused-vars */
  addToast: (title: string, content: string | ReactNode,typeToast?: StylesType) => void
  clearToasts: () => void
}

interface Props {
  children: ReactNode
}

export const ToastContext = createContext({} as ToastContextType)
export type StylesType = 'info' | 'main' | 'negative' | 'positive'

export function ToastProvider(props: Props) {
  const [toast, setToast] = useState<ToastInterface | null>(null)
  const [open, setOpen] = useState(false)
  const timerRef = useRef(0)
  const { theme } = useContext(AuthContext)

  const toastStyles = {
    info: style.root_info,
    main: style.root_main,
    negative: style.root_negative,
    positive: style.root_positive
  }
  const titleStyles = {
    info: style.title_info,
    main: style.title_main,
    negative: style.title_negative,
    positive: style.title_positive
  }
  const descriptionStyles = {
    info: style.description_info,
    main: style.description_main,
    negative: style.description_negative,
    positive: style.description_positive
  }

  const closeButtonStyles = {
    info: style.close_button_info,
    main: style.close_button_main,
    negative: style.close_button_negative,
    positive: style.close_button_positive
  }

  const iconStyles = {
    info:  <Icon name="Info" />,
    main: <Icon name="Info" />,
    negative: <Icon name="AlertTriangle" />,
    positive: <Icon name="CheckCircle2" />
  }

  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  async function addToast(title: string, content: string | ReactNode, typeToast?: StylesType) {
    const newToast: ToastInterface = {
      title,
      content,
      typeToast: typeToast || 'info'
    }
    setToast(newToast)
    setOpen(false)
    window.clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(() => {
      setOpen(true)
    }, 100)

  }

  function clearToasts() {
    setToast(null)
    clearTimeout(timerRef.current)
  }

  return (
    <ToastContext.Provider value={{ addToast, clearToasts }} >
      {props.children}
      <ToastPrimitive.Provider swipeDirection="right" duration={5000} >
        <div data-theme={theme}></div>
        <ToastPrimitive.Root
          open={open} onOpenChange={setOpen}
          className={`${style.root} ${toast?.typeToast && toastStyles[toast?.typeToast]}`}
          data-testid='toast-item'
          data-cy="toast-item"
          data-theme={theme}
          data-status={toast?.typeToast}
        >
          {toast &&
            <>
              <div>
                <ToastPrimitive.Title
                  data-testid='toast-title'
                  className={`${style.title} ${toast?.typeToast && titleStyles[toast?.typeToast]}`}
                >
                  <span aria-hidden className={style['icon']}>{toast?.typeToast && iconStyles[toast?.typeToast]}</span>
                  {toast.title}
                </ToastPrimitive.Title>

                <ToastPrimitive.Description
                  data-testid='toast-description'
                  className={`${style.description} ${toast?.typeToast && descriptionStyles[toast?.typeToast]}`}
                >
                  {toast.content}
                </ToastPrimitive.Description>
              </div>
              <ToastPrimitive.Close aria-label="Close"
                className={`${style.close_button} ${toast?.typeToast && closeButtonStyles[toast?.typeToast]} `}
                data-testid='toast-close'>
                <span aria-hidden><Icon name="X" style={{ fontSize: '16px' }} /></span>
              </ToastPrimitive.Close>
            </>
          }
        </ToastPrimitive.Root>
        <ToastPrimitive.Viewport className={style['viewport']} />
      </ToastPrimitive.Provider>
    </ToastContext.Provider >
  )
}
